import React, { useState } from 'react';
import axios from 'axios';
import TextAreaWithShortcuts from './TextAreaWithShortcuts';

const ExtractedTextComponent = ({
  selectedText,
  setFlashcards,
  apiKey,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableContent, setEditableContent] = useState(selectedText);

  // Toggle between extract and edit mode
  const toggleMode = () => {
    setIsEditMode(!isEditMode);

    // If switching to edit mode, initialize editableContent with selectedText
    if (!isEditMode) {
      setEditableContent(selectedText);
    }
  };

  // Handle content editing
  const handleTextChange = (e) => {
    setEditableContent(e.target.value);
  };

  const handleContentChange = (newContent) => {
    setEditableContent(newContent);
  };

  // Handle processing content with OpenAI API
  const processContent = async () => {
    try {
      const prompt = `Convert the following content into a JSON array of flashcards. Each flashcard should have a "front" and "back" field. The content is formatted with dividers:

${editableContent}

Example of desired JSON output:
[
  { "front": "Question 1", "back": "Answer 1" },
  { "front": "Question 2", "back": "Answer 2" }
]

Ensure that the JSON is properly formatted.`;

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: prompt }],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const assistantReply = response.data.choices[0].message.content;
      const flashcardsJson = JSON.parse(assistantReply);
      console.log(flashcardsJson)

      setFlashcards(flashcardsJson);
    } catch (error) {
      console.error('Error processing content:', error);
      alert('An error occurred while processing the content.');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh', // Full height of the viewport
        padding: '1rem',
        borderRight: '1px solid #ccc',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <h3>{isEditMode ? 'Edit Content' : 'Extracted Content'}</h3>
        <button onClick={toggleMode}>
          {isEditMode ? 'Switch to Extract Mode' : 'Switch to Edit Mode'}
        </button>
      </div>

      {!isEditMode ? (
        <div style={{ flex: 1, overflow: 'auto' }}>
          <p>{selectedText}</p>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {/* <textarea
          value={editableContent}
          onChange={handleTextChange}
          style={{
            flex: 1, // Make the textarea fill the available space
            width: '100%',
            padding: '1rem',
            marginBottom: '1rem',
            boxSizing: 'border-box',
            color: 'black', // Set text color to black
            backgroundColor: 'white', // Optionally set background color to ensure good contrast
          }}
        /> */}
        <TextAreaWithShortcuts
          initialContent={editableContent}
          onContentChange={handleContentChange}
          
        />
          <button
            onClick={processContent}
            style={{
              alignSelf: 'flex-end',
              padding: '0.5rem 1rem',
              marginTop: '1rem',
            }}
          >
            Process with AI
          </button>
        </div>
      )}
    </div>
  );
};

export default ExtractedTextComponent;
