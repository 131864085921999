import React from 'react';

const CollapseExpandControls = ({
    showExtractedText, setShowExtractedText,
    showPDFViewer, setShowPDFViewer,
    showFlashcardEntry, setShowFlashcardEntry
}) => {
    return (
        <div style={{
            width: '100%', // Full width of the page
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Optional shadow for separation
            padding: '10px 20px', // Padding for responsiveness
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            gap: '10px' // Space between buttons
        }}>
            
            <button onClick={() => setShowExtractedText(!showExtractedText)} style={{ margin: '0 10px' }}>
                {showExtractedText ? 'Hide Extracted Text' : 'Show Extracted Text'}
            </button>
            <button onClick={() => setShowPDFViewer(!showPDFViewer)} style={{ margin: '0 10px' }}>
                {showPDFViewer ? 'Hide Content Viewer' : 'Show Content Viewer'}
            </button>
            <button onClick={() => setShowFlashcardEntry(!showFlashcardEntry)} style={{ margin: '0 10px' }}>
                {showFlashcardEntry ? 'Hide Flashcard Entry' : 'Show Flashcard Entry'}
            </button>
        </div>
    );
};

export default CollapseExpandControls;
