// TypeAnswerFlashcard.js
import React, { useState } from 'react';

const TypeAnswerFlashcard = ({ flashcard }) => {
  const [userAnswer, setUserAnswer] = useState('');
  const [showResult, setShowResult] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowResult(true);
  };

  const handleRetry = () => {
    setUserAnswer('');
    setShowResult(false);
  };

  const isCorrect = userAnswer.trim().toLowerCase() === flashcard.back.trim().toLowerCase();

  return (
    <div className="type-answer-flashcard">
      <div className="question">
        <p>{flashcard.front}</p>
      </div>
      {showResult ? (
        <div className="result">
          <p>
            Your Answer: <strong>{userAnswer}</strong>
          </p>
          <p>
            Correct Answer: <strong>{flashcard.back}</strong>
          </p>
          {isCorrect ? (
            <p style={{ color: 'green' }}>Correct!</p>
          ) : (
            <p style={{ color: 'red' }}>Incorrect.</p>
          )}
          <button onClick={handleRetry}>Try Again</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={userAnswer}
            onChange={(e) => setUserAnswer(e.target.value)}
            placeholder="Type your answer..."
            required
            style={{ width: '100%', padding: '0.5rem', marginBottom: '1rem' }}
          />
          <button type="submit">Submit Answer</button>
        </form>
      )}
    </div>
  );
};

export default TypeAnswerFlashcard;
