import React, { useEffect, useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { useAuth } from '../../contexts/AuthContext';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';  // Assuming your Firebase setup is in 'firebase.js'

const SettingsPage = () => {
  const { currentUser } = useAuth(); // Get the currently authenticated user

  // State variables for all settings
  const [theme, setTheme] = useState('light');
  const [language, setLanguage] = useState('English');
  const [notifications, setNotifications] = useState({
    upcomingMatches: true,
    teamNews: true,
    eloUpdates: true,
  });
  const [favoriteTeams, setFavoriteTeams] = useState([]);
  const [subscribeToTeamNews, setSubscribeToTeamNews] = useState(false);
  const [dataSharing, setDataSharing] = useState(false);
  const [profileVisibility, setProfileVisibility] = useState('public');
  const [dateFormat, setDateFormat] = useState('DD/MM/YYYY');
  const [timeZone, setTimeZone] = useState('AEST');

  // Load settings from Firestore when the page loads
  useEffect(() => {
    if (currentUser) {
      const loadUserSettings = async () => {
        try {
          const userSettingsDoc = doc(db, 'users', currentUser.uid);
          const docSnapshot = await getDoc(userSettingsDoc);
  
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            setTheme(data.theme || 'light');
            setLanguage(data.language || 'English');
            setNotifications(data.notifications || {
              upcomingMatches: true,
              teamNews: true,
              eloUpdates: true,
            });
            setFavoriteTeams(data.favoriteTeams || []);
            setSubscribeToTeamNews(data.subscribeToTeamNews || false);
            setDataSharing(data.dataSharing || false);
            setProfileVisibility(data.profileVisibility || 'public');
            setDateFormat(data.dateFormat || 'DD/MM/YYYY');
            setTimeZone(data.timeZone || 'AEST');
          } else {
            // Set default settings if document does not exist
            const defaultSettings = {
              theme: 'light',
              language: 'English',
              notifications: {
                upcomingMatches: true,
                teamNews: true,
                eloUpdates: true,
              },
              favoriteTeams: [],
              subscribeToTeamNews: false,
              dataSharing: false,
              profileVisibility: 'public',
              dateFormat: 'DD/MM/YYYY',
              timeZone: 'AEST',
            };
            await setDoc(userSettingsDoc, defaultSettings);
  
            // Apply the default settings to state
            setTheme(defaultSettings.theme);
            setLanguage(defaultSettings.language);
            setNotifications(defaultSettings.notifications);
            setFavoriteTeams(defaultSettings.favoriteTeams);
            setSubscribeToTeamNews(defaultSettings.subscribeToTeamNews);
            setDataSharing(defaultSettings.dataSharing);
            setProfileVisibility(defaultSettings.profileVisibility);
            setDateFormat(defaultSettings.dateFormat);
            setTimeZone(defaultSettings.timeZone);
          }
        } catch (error) {
          console.error("Error loading user settings:", error);
        }
      };
      loadUserSettings();
    }
  }, [currentUser]);

  // Save settings to Firestore
  const saveSettingsToFirestore = async () => {
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(userSettingsDoc, {
          theme,
          language,
          notifications,
          favoriteTeams,
          subscribeToTeamNews,
          dataSharing,
          profileVisibility,
          dateFormat,
          timeZone,
        }, { merge: true }); // Merges the new data with existing data
        alert('Settings updated successfully!');
      } catch (error) {
        console.error("Error saving settings:", error);
        alert('Failed to save settings. Please try again.');
      }
    } else {
      alert('You need to be logged in to save settings.');
    }
  };

  // Event handlers
  const handleThemeChange = async (e) => {
    const newTheme = e.target.value;
    setTheme(newTheme);
  
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { theme: newTheme },
          { merge: true } // Merge with existing data
        );
        console.log('Theme updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating theme:', error);
        // Optionally, display an error message to the user
      }
    }
  };

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
  
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { language: newLanguage },
          { merge: true } // Merge with existing data
        );
        // Optionally, display a success message or handle state updates
        console.log('Language updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating language:', error);
        // Optionally, display an error message to the user
      }
    }
  };

  const handleNotificationsChange = async (e) => {
    const { name, checked } = e.target;
  
    // Update local state
    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      [name]: checked,
    }));
  
    // Update Firestore
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { 
            notifications: {
              ...notifications, 
              [name]: checked 
            } 
          },
          { merge: true }
        );
        console.log('Notification settings updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating notification settings:', error);
      }
    }
  };
  
  

  const handleFavoriteTeamsChange = async (e) => {
    const { name, checked } = e.target;
  
    // Update local state
    setFavoriteTeams((prevFavoriteTeams) => {
      const updatedTeams = checked
        ? [...prevFavoriteTeams, name] // Add the team if checked
        : prevFavoriteTeams.filter((team) => team !== name); // Remove if unchecked
  
      // Update Firestore with new list of favorite teams
      if (currentUser) {
        try {
          const userSettingsDoc = doc(db, 'users', currentUser.uid);
          setDoc(
            userSettingsDoc,
            { favoriteTeams: updatedTeams },
            { merge: true }
          );
          console.log('Favorite teams updated successfully in Firestore.');
        } catch (error) {
          console.error('Error updating favorite teams:', error);
        }
      }
  
      return updatedTeams; // Update the state
    });
  };
  

  const handleSubscribeToTeamNewsChange = async (e) => {
    const isChecked = e.target.checked;
    
    // Update local state
    setSubscribeToTeamNews(isChecked);
  
    // Update Firestore
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { subscribeToTeamNews: isChecked }, // Update only subscribeToTeamNews
          { merge: true } // Merge to avoid overwriting other fields
        );
        console.log('Team news subscription updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating team news subscription:', error);
      }
    }
  };
  

  const handleDataSharingChange = async (e) => {
    const isChecked = e.target.checked;
    
    // Update local state
    setDataSharing(isChecked);
  
    // Update Firestore
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { dataSharing: isChecked }, // Update the dataSharing field
          { merge: true } // Merge to avoid overwriting other fields
        );
        console.log('Data sharing preference updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating data sharing preference:', error);
      }
    }
  };
  

  const handleProfileVisibilityChange = async (e) => {
    const newVisibility = e.target.value;
    
    // Update local state
    setProfileVisibility(newVisibility);
  
    // Update Firestore
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { profileVisibility: newVisibility }, // Update the profileVisibility field
          { merge: true } // Merge to avoid overwriting other fields
        );
        console.log('Profile visibility updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating profile visibility:', error);
      }
    }
  };
  

  const handleDateFormatChange = async (e) => {
    const newDateFormat = e.target.value;
    
    // Update local state
    setDateFormat(newDateFormat);
  
    // Update Firestore
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { dateFormat: newDateFormat }, // Update the dateFormat field
          { merge: true } // Merge to avoid overwriting other fields
        );
        console.log('Date format updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating date format:', error);
      }
    }
  };
  

  const handleTimeZoneChange = async (e) => {
    const newTimeZone = e.target.value;
  
    // Update local state
    setTimeZone(newTimeZone);
  
    // Update Firestore
    if (currentUser) {
      try {
        const userSettingsDoc = doc(db, 'users', currentUser.uid);
        await setDoc(
          userSettingsDoc,
          { timeZone: newTimeZone }, // Update the timeZone field
          { merge: true } // Merge to avoid overwriting other fields
        );
        console.log('Time zone updated successfully in Firestore.');
      } catch (error) {
        console.error('Error updating time zone:', error);
      }
    }
  };
  

  const teams = [
    'Adelaide Crows',
    'Brisbane Lions',
    'Carlton Blues',
    'Collingwood Magpies',
    'Essendon Bombers',
    'Fremantle Dockers',
    'Geelong Cats',
    'Gold Coast Suns',
    'Greater Western Sydney Giants',
    'Hawthorn Hawks',
    'Melbourne Demons',
    'North Melbourne Kangaroos',
    'Port Adelaide Power',
    'Richmond Tigers',
    'St Kilda Saints',
    'Sydney Swans',
    'West Coast Eagles',
    'Western Bulldogs'
  ];
  
  return (
    <div className="settings-page p-8 relative max-w-5xl mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-7xl border-slate-200 border-2">
      <h1 className="text-2xl font-bold mb-4">Settings</h1>

      {/* General Settings */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">General Settings</h2>

        {/* Theme Selection */}
        <div className="mb-4">
          <label className="block mb-2">Theme:</label>
          <select
            value={theme}
            onChange={handleThemeChange}
            className="border rounded p-2 w-full"
          >
            <option value="light">Light Mode</option>
            <option value="dark">Dark Mode</option>
          </select>
        </div>

        {/* Language Selection */}
        <div className="mb-4">
          <label className="block mb-2">Language:</label>
          <select
            value={language}
            onChange={handleLanguageChange}
            className="border rounded p-2 w-full"
          >
            <option value="English">English</option>
            <option value="Japanese">Japanese</option>
            {/* Add more languages if needed */}
          </select>
        </div>

        {/* Notification Preferences */}
        <div className="mb-4">
          <label className="block mb-2">Notification Preferences:</label>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              name="upcomingMatches"
              checked={notifications.upcomingMatches}
              onChange={handleNotificationsChange}
              className="mr-2"
            />
            <label>Upcoming Matches</label>
          </div>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              name="teamNews"
              checked={notifications.teamNews}
              onChange={handleNotificationsChange}
              className="mr-2"
            />
            <label>Team News</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="eloUpdates"
              checked={notifications.eloUpdates}
              onChange={handleNotificationsChange}
              className="mr-2"
            />
            <label>ELO Rating Updates</label>
          </div>
        </div>
      </section>

      {/* Account Settings */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Account Settings</h2>

        {/* Profile Picture Upload */}
        <div className="mb-4">
          <label className="block mb-2">Profile Picture:</label>
          <input type="file" accept="image/*" className="block w-full" />
          {/* Implement the upload functionality */}
        </div>

      </section>

      {/* Team Preferences */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Team Preferences</h2>

        {/* Favorite Teams Selection */}
        <div className="mb-4">
          <label className="block mb-2">Favorite Teams:</label>
          <div className="grid grid-cols-2 gap-2">
            {teams.map((team) => (
              <div key={team} className="flex items-center">
                <input
                  type="checkbox"
                  name={team}
                  checked={favoriteTeams.includes(team)}
                  onChange={handleFavoriteTeamsChange}
                  className="mr-2"
                />
                <label>{team}</label>
              </div>
            ))}
          </div>
        </div>

        {/* Team News Subscription */}
        <div>
          <label className="block mb-2">Subscribe to Team News:</label>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={subscribeToTeamNews}
              onChange={handleSubscribeToTeamNewsChange}
              className="mr-2"
            />
            <label>Receive news updates for favorite teams</label>
          </div>
        </div>
      </section>

      {/* Privacy Settings */}
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Privacy Settings</h2>

        {/* Data Sharing */}
        <div className="mb-4">
          <label className="block mb-2">Data Sharing:</label>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={dataSharing}
              onChange={handleDataSharingChange}
              className="mr-2"
            />
            <label>Allow data sharing with third-party services</label>
          </div>
        </div>

        {/* Profile Visibility */}
        <div>
          <label className="block mb-2">Profile Visibility:</label>
          <select
            value={profileVisibility}
            onChange={handleProfileVisibilityChange}
            className="border rounded p-2 w-full"
          >
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>
        </div>
      </section>

      {/* Display Settings */}
      <section>
        <h2 className="text-xl font-semibold mb-4">Display Settings</h2>

        {/* Match Date Format */}
        <div className="mb-4">
          <label className="block mb-2">Date Format:</label>
          <select
            value={dateFormat}
            onChange={handleDateFormatChange}
            className="border rounded p-2 w-full"
          >
            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
          </select>
        </div>

        {/* Time Zone Selection */}
        <div>
          <label className="block mb-2">Time Zone:</label>
          <select
            value={timeZone}
            onChange={handleTimeZoneChange}
            className="border rounded p-2 w-full"
          >
            <option value="AEST">Australian Eastern Standard Time (AEST)</option>
            <option value="ACST">Australian Central Standard Time (ACST)</option>
            <option value="AWST">Australian Western Standard Time (AWST)</option>
          </select>
        </div>
      </section>

      {/* Save Settings Button */}
      <div className="mt-8">
        <button
          onClick={saveSettingsToFirestore}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Save Settings
        </button>
      </div>
    </div>
  );
};

export default SettingsPage;
