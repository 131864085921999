// src/pages/PDFViewerPage.js
import React, { useState, useRef, useEffect } from 'react';
import ExtractedTextComponent from '../../components/StudyCreateContentComponents/BatchContentCreationComponents/ExtractedTextComponent';
import PDFViewerComponent from '../../components/StudyCreateContentComponents/BatchContentCreationComponents/PDFViewerComponent';
import WebPageViewerComponent from '../../components/StudyCreateContentComponents/BatchContentCreationComponents/WebPageViewerComponent';
import FlashcardEntryComponent from '../../components/StudyCreateContentComponents/BatchContentCreationComponents/FlashcardEntryComponent';
import CollapseExpandControls from '../../components/StudyCreateContentComponents/BatchContentCreationComponents/CollapseExpandControls';  // Import the new component
import { Document, Page, pdfjs } from 'react-pdf';
import WebPageViewerDOMPurifyComponent from '../../components/StudyCreateContentComponents/BatchContentCreationComponents/WebPageViewerDOMPurifyComponent';


// No need to import workerSrc manually; set the workerSrc using the CDN or local path
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const PDFViewerPage = ({ unitId, deckId }) => {
    const [selectedText, setSelectedText] = useState('');
    const [flashcards, setFlashcards] = useState([]);
    
    const [viewerType, setViewerType] = useState('webpage'); // 'pdf' or 'webpage'
    const [webPageUrl, setWebPageUrl] = useState('https://example.com'); // Default URL
    
    // State for column widths
    const [leftWidth, setLeftWidth] = useState(450); // Default width
    const [middleWidth, setMiddleWidth] = useState(800); // Default width
    const [remainingWidth, setRemainingWidth] = useState(window.innerWidth - 1250); // Space left after default sizes

  
    // Refs for the resizable dividers
    const leftDividerRef = useRef(null);
    const middleDividerRef = useRef(null);
  
    // States to manage visibility of each section
    const [showExtractedText, setShowExtractedText] = useState(true);
    const [showPDFViewer, setShowPDFViewer] = useState(true);
    const [showFlashcardEntry, setShowFlashcardEntry] = useState(true);


    // Handle window resize for dynamic layout
    useEffect(() => {
      const handleResize = () => {
          setRemainingWidth(window.innerWidth - (leftWidth + middleWidth));
      };
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, [leftWidth, middleWidth]);

  // Update the widths based on the visible sections
  const adjustWidths = () => {
      let totalVisible = 0;
      if (showExtractedText) totalVisible += leftWidth;
      if (showPDFViewer) totalVisible += middleWidth;

      const newRemainingWidth = window.innerWidth - totalVisible;
      setRemainingWidth(newRemainingWidth);
  };

  useEffect(() => {
      adjustWidths();
  }, [showExtractedText, showPDFViewer, showFlashcardEntry]);


    const handleTextSelect = () => {
      const text = window.getSelection().toString();
      setSelectedText(text);
    };
  
    const handleMouseDown = (e, direction) => {
      const startX = e.clientX;
      const startLeftWidth = leftWidth;
      const startMiddleWidth = middleWidth;
  
      const handleMouseMove = (e) => {
        const newLeftWidth = startLeftWidth + (e.clientX - startX);
        if (direction === 'left') {
          setLeftWidth(newLeftWidth);
        } else {
          const newMiddleWidth = startMiddleWidth + (e.clientX - startX);
          setMiddleWidth(newMiddleWidth);
        }
      };
  
      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
  
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  
    return (
      <div>
        <div style={{ padding: '1rem' }}>
            <label>
                Select Viewer Type:
                <select value={viewerType} onChange={(e) => setViewerType(e.target.value)}>
                <option value="webpage">Web Page</option>
                    <option value="pdf">PDF</option>
                </select>
            </label>
            {viewerType === 'webpage' && (
                <div style={{ marginTop: '0.5rem' }}>
                    <label>
                        Enter Web Page URL:
                        <input
                            type="text"
                            value={webPageUrl}
                            onChange={(e) => setWebPageUrl(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </label>
                </div>
            )}
        </div>


      {/* Collapse/expand control bar */}
      <CollapseExpandControls
          showExtractedText={showExtractedText}
          setShowExtractedText={setShowExtractedText}
          showPDFViewer={showPDFViewer}
          setShowPDFViewer={setShowPDFViewer}
          showFlashcardEntry={showFlashcardEntry}
          setShowFlashcardEntry={setShowFlashcardEntry}
      />

      <div style={{ display: 'grid', gridTemplateColumns: `${leftWidth}px ${middleWidth}px auto`, height: '100vh', position: 'relative' }}>

       {/* Extracted Text Section */}
       {showExtractedText && (
          <div
            style={{
              overflow: 'auto',
              padding: '1rem',
              borderRight: '1px solid #ccc',
            }}
          >
            <ExtractedTextComponent
              selectedText={selectedText}
              setFlashcards={setFlashcards}
              apiKey={apiKey}
            />
          </div>
        )}

            {/* Viewer Section */}
            {showPDFViewer && (
                <div
                    style={{ overflow: 'auto', padding: '1rem' }}
                    onMouseUp={handleTextSelect}
                >
                    {viewerType === 'pdf' ? (
                        <PDFViewerComponent />
                    ) : (
                        <WebPageViewerComponent url={webPageUrl} />
                        // <WebPageViewerDOMPurifyComponent url={webPageUrl} />

                    )}
                </div>
            )}


        {/* Flashcard Entry Section */}
        {showFlashcardEntry && (
          <div
            style={{
              overflow: 'auto',
              padding: '1rem',
              borderLeft: '1px solid #ccc',
            }}
          >
            <FlashcardEntryComponent 
            flashcards={flashcards}
            setFlashcards={setFlashcards}

                  unitId={unitId}    // Pass unitId
                  deckId={deckId}    // Pass deckId
             />
          </div>
        )}

       {/* Dividers */}
       {showExtractedText && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: `${leftWidth}px`,
                        height: '100%',
                        width: '5px',
                        cursor: 'col-resize',
                        backgroundColor: '#ccc'
                    }}
                    onMouseDown={(e) => handleMouseDown(e, 'left')}
                    ref={leftDividerRef}
                ></div>
            )}

            {showPDFViewer && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: `${leftWidth + middleWidth}px`,
                        height: '100%',
                        width: '5px',
                        cursor: 'col-resize',
                        backgroundColor: '#ccc'
                    }}
                    onMouseDown={(e) => handleMouseDown(e, 'middle')}
                    ref={middleDividerRef}
                ></div>
            )}
      </div>
      </div>
  );
};

  export default PDFViewerPage;