// src/components/StudyCreateContentComponents/FlashcardEditable.js
import React from 'react';

const FlashcardEntryEditableComponent = ({ flashcard, onChange, onDelete }) => {
    const handleFrontChange = (e) => {
      onChange({ ...flashcard, front: e.target.value });
    };
  
    const handleBackChange = (e) => {
      onChange({ ...flashcard, back: e.target.value });
    };
  
    const handleTypeChange = (e) => {
      onChange({ ...flashcard, type: e.target.value });
    };
  
    return (
      <div
        style={{
          border: '1px solid #ccc',
          padding: '1rem',
          marginBottom: '1rem',
        }}
      >
        <label>
          Front:
          <input
            type="text"
            value={flashcard.front}
            onChange={handleFrontChange}
            placeholder="Front"
            style={{ width: '100%', marginBottom: '0.5rem' }}
          />
        </label>
        <label>
          Back:
          <input
            type="text"
            value={flashcard.back}
            onChange={handleBackChange}
            placeholder="Back"
            style={{ width: '100%', marginBottom: '0.5rem' }}
          />
        </label>
        <label>
          Type:
          <select
            value={flashcard.type || 'flip'} // Default to 'flip' if type is undefined
            onChange={handleTypeChange}
            style={{ width: '100%', marginBottom: '0.5rem' }}
          >
            <option value="flip">Flip</option>
            <option value="type_to_answer">Type to Answer</option>
            <option value="multiple_choice">Multiple Choice</option>
            {/* Add more types as necessary */}
          </select>
        </label>
        <button onClick={onDelete} style={{ backgroundColor: 'red', color: 'white' }}>
          Delete
        </button>
      </div>
    );
  };
  
  export default FlashcardEntryEditableComponent;