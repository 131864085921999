// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
/* Banner/Headers/Navbars */
  /* Banner Pro Feature Purchase Page */
    import ProBanner from './components/MonetizationComponents/ProBanner';

  /* Header for Navigation to Pages */
    import Header from './components/Header';

  /* Home/Dashboard Pages */
    import HomePage from './pages/MainPages/HomePage';
    import DashboardPage from './pages/MainPages/DashboardPage';
    import UnitsPage from './pages/MainPages/UnitsPage';
    /* Study Navigation Pages */
    import StudyPage from './pages/MainPages/StudyPage';
/* User Profile/ Auth Pages */
  import LoginPage from './pages/UserAndAuthPages/LoginPage';
  import SignupPage from './pages/UserAndAuthPages/SignupPage';
  import UserInfoFormPage from './pages/UserAndAuthPages/UserInfoFormPage';
  import UserProfilePage from './pages/UserAndAuthPages/UserProfilePage';
  import SettingsPage from './pages/UserAndAuthPages/SettingsPage';

/* Study Page Sub Pages */

  /* Study Card/Content Pages */
    import FlashcardPage from './pages/StudyCardContentPages/FlashcardPage';  // Ensure the correct path to FlashcardPage
    import CodeEditorPage from './pages/StudyCardContentPages/CodeEditorPage';
    import CodeSandboxPage from './pages/StudyCardContentPages/CodeSandboxPage';

  /* Study Create Content Pages */
    import EditUnitPage from './pages/StudyCreateContentPages/EditUnitPage';
    import EditDeckPage from './pages/StudyCreateContentPages/EditDeckPage'; // Create this component similarly
    import FlashPDFPage from './pages/StudyCreateContentPages/FlashPDFPage';

/* Monetization/ Funding Pages */
  import ProPage from './pages/MonetizationPages/ProPage';


/* Future Expansion Pages */
  import ZoomableCanvasPage from './pages/FutureExpansionPages/ZoomableCanvasPage';

/* Original Dont Use Anymore Pages */
  // import OriginalFlashCardPage from './pages/OriginalConceptPages/OriginalFlashCardPage';
  // import PDFViewerPage from './pages/StudyCreateContentPages/PDFViewerPage';  // Ensure the correct path to FlashcardPage


/* Not sure Pages */
  // import FlashcardCreatorPage from './pages/FlashcardCreatorPage';
  // import ProblemPage from './pages/ProblemPage';
  // import FlashCardStudyScreen from './pages/UnusedPages/FlashCardStudyScreen';
  // import FlashcardReviewPage from './pages/UnusedPages/FlashcardReviewPage';
  // import Navbar from './components/Navbar';

function App() {
  // usePageViews();

  return (
    
    <AuthProvider>
      <Router>
         {/* Banner/Headers/Navbars  */}
            {/* Banner Pro Feature Purchase Page */}
              {/* <ProBanner /> */}
            {/* Header for Navigation to Pages */}
              <Header />

        {/* Routes to Pages */}
        <Routes>
          {/* Home/Dashboard Pages */}
            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={<PrivateRoute element={DashboardPage} />} />
            <Route path="/units" element={<UnitsPage />} />
            <Route path="/settings" element={<SettingsPage />} />


          {/* User Profile/ Auth Pages */}
            {/* <Route path="/login" element={<LoginPage />} /> */}
            {/* <Route path="/signup" element={<SignupPage />} /> */}
            <Route path="/profile" element={<UserProfilePage />} />
            <Route path="/user-info-form" element={<UserInfoFormPage />} />

          {/* Study Pages */}

            {/* Study Navigation Pages */}
              <Route path="/study" element={<StudyPage />} />

            {/* Study Card/Content Pages */}
              <Route path="/study/units/:unitId/decks/:deckId" element={<FlashcardPage />} />

              <Route path="/codeeditor" element={<CodeEditorPage />} />
              <Route path="/codesandbox" element={<CodeSandboxPage />} />

            {/* Study Create Content Pages */}
              <Route path="/study/edit-unit/:unitId" element={<EditUnitPage />} />
              <Route path="/study/edit-deck/:unitId/:deckId" element={<EditDeckPage />} />
              <Route path="/study/flash-pdf/:unitId/:deckId" element={<FlashPDFPage />} />

          {/* Monetization/ Funding Pages */}
            {/* <Route path="/pro" element={<ProPage />} /> */}
     
          {/* Original Dont Use Anymore Pages */}
            {/* <Route path="/flashcards" element={<OriginalFlashCardPage />} /> */}
            {/* <Route path="/flashpdf" element={<FlashPDFPage />} /> */}

          {/* Future Expansion Pages */}
            <Route path="/canvas" element={<ZoomableCanvasPage />} />


        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
