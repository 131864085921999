import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const FlashcardEditable = ({ flashcard, onDelete, unitId, deckId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editFlashcard, setEditFlashcard] = useState({
    flashcard_front: flashcard.flashcard_front,
    flashcard_back: flashcard.flashcard_back,
    type: flashcard.type || 'flip', // Ensure default value for type
  });

  // Function to handle saving the edited flashcard
  const handleSave = async () => {
    if (!editFlashcard.type) {
      // Set default type if it is undefined or empty
      setEditFlashcard((prev) => ({
        ...prev,
        type: 'flip',
      }));
    }

    try {
      const flashcardRef = doc(db, `units/${unitId}/decks/${deckId}/flashcards`, flashcard.id);
      await updateDoc(flashcardRef, {
        flashcard_front: editFlashcard.flashcard_front,
        flashcard_back: editFlashcard.flashcard_back,
        type: editFlashcard.type || '', // Use default value if type is undefined
      });
      setIsEditing(false); // Exit edit mode after saving
    } catch (error) {
      console.error('Error updating flashcard:', error);
    }
  };



  // Handle cancel editing by resetting the state and exiting edit mode
  const handleCancel = () => {
    setEditFlashcard({
      flashcard_front: flashcard.flashcard_front,
      flashcard_back: flashcard.flashcard_back,
      type: flashcard.type || 'flip',
    });
    setIsEditing(false); // Exit edit mode without saving
  };

  // Handle double click to enter edit mode
  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  return (
    <li className="flashcard-item" onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <div className="flashcard-edit">
          <input
            type="text"
            value={editFlashcard.flashcard_front}
            onChange={(e) =>
              setEditFlashcard({ ...editFlashcard, flashcard_front: e.target.value })
            }
            className="input"
            placeholder="Question"
          />
          <input
            type="text"
            value={editFlashcard.flashcard_back}
            onChange={(e) =>
              setEditFlashcard({ ...editFlashcard, flashcard_back: e.target.value })
            }
            className="input"
            placeholder="Answer"
          />
          <select
            value={editFlashcard.type}
            onChange={(e) =>
              setEditFlashcard({ ...editFlashcard, type: e.target.value })
            }
            className="input"
          >
            <option value="flip">Flip</option>
            <option value="type_to_answer">Type to Answer</option>
            <option value="multiple_choice">Multiple Choice</option>
            {/* Add more types if necessary */}
          </select>
          <div className="flashcard-buttons">
            <button onClick={handleSave} className="save-button">
              Save
            </button>
            <button onClick={handleCancel} className="cancel-button">
              Cancel
            </button>
          </div>        </div>
      ) : (
        <div>
          <strong>Q:</strong> {flashcard.flashcard_front}
          <br />
          <strong>A:</strong> {flashcard.flashcard_back}
          <br />
          <strong>Type:</strong> {flashcard.type || ''} {/* Display default if type is missing */}
        </div>
      )}
      <button onClick={() => onDelete(flashcard.id)} className="delete-button">
        Delete
      </button>
    </li>
  );
};
export default FlashcardEditable;
