import React, { useState } from 'react';

import './CreateDeckModal.css';

const CreateDeckModal = ({ showModal, setShowModal, onCreateDeck }) => {  // Ensure userId is passed as a prop
  const [unitName, setUnitName] = useState('');
  const [unitFull, setUnitFull] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create the new unit object
    const newUnit = {
      unit_name: unitName,
      unit_full: unitFull,
    };

    // Call the handleAddUnit function passed from StudyPage to update the state
    onCreateDeck(newUnit);

    // Reset the form and close the modal
    setUnitName('');
    setUnitFull('');
    setShowModal(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>New Study Topic Name:</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="dark-textarea"
            placeholder="Enter Study Topic name"
            value={unitFull}
            onChange={(e) => setUnitFull(e.target.value)}
            required
            rows="3"
            style={{ width: '100%', padding: '10px' }}
          />
        <div style={{ marginTop: '20px' }}>
          <button type="button" onClick={() => setShowModal(false)} className="cancel-button">Cancel</button>
          <button type="submit" className="ok-button">OK</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default CreateDeckModal;
