// src/components/StudyCreateContentComponents/WebPageViewerComponent.js
import React from 'react';

const WebPageViewerComponent = ({ url }) => {
    return (
        <iframe
            src={url}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Web Page Viewer"
        ></iframe>
    );
};

export default WebPageViewerComponent;
