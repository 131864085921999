import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './UserProfilePage.css'; // Import your CSS file

const UserProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          const userInfoRef = doc(userRef, 'user-info', 'details');
          const userInfoDoc = await getDoc(userInfoRef);

          if (userInfoDoc.exists()) {
            const userInfoData = userInfoDoc.data();
            const userDocData = userDoc.exists() ? userDoc.data() : {};

            setUserData({
              ...userInfoData,
              profileImageURL: userDocData.profileImageURL || '',
            });
          } else {
            // User data doesn't exist, redirect to UserInfoFormPage
            navigate('/user-info-form');
          }
        } else {
          // No user signed in, redirect to sign-in page
          navigate('/sign-in');
        }
      } catch (error) {
        setFetchError('Failed to fetch user data.');
        console.error(error);
      }
    };

    fetchUserData();
  }, [auth, db, navigate]);

  if (fetchError) {
    return <div>{fetchError}</div>;
  }

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-profile-container">
      <div className="profile-header">
        <h2>Your Profile</h2>
        {userData.profileImageURL ? (
          <img src={userData.profileImageURL} alt="Profile" className="profile-image" />
        ) : (
          <div className="profile-image-placeholder">No Profile Image</div>
        )}
      </div>
      <div className="profile-details">
        <p><strong>Faculty:</strong> {userData.faculty}</p>
        <p><strong>Grade Level:</strong> {userData.gradeLevel}</p>
        <p><strong>Degree:</strong> {userData.degree}</p>
        <p><strong>Degree Type:</strong> {userData.degreeType}</p>
        <p><strong>Study Hours per Week:</strong> {userData.studyHours}</p>
        <p><strong>Email Reminder Frequency:</strong> {userData.emailFrequency}</p>
        <p><strong>Student Type:</strong> {userData.studentType}</p>
      </div>
      <button onClick={() => navigate('/user-info-form')}>Edit Profile</button>
    </div>
  );
};

export default UserProfilePage;
