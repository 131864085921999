import React from 'react';
import CreateDeckModal from './CreateDeckModal'; // Import the modal component

const BottomFunctionBar = ({ setShowModal }) => { // Receive setShowModal as a prop
  const handleCreateDeck = () => {
    setShowModal(true); // Show the modal when "Create Deck" is clicked
  };

  return (
    <>
      {/* Fixed footer button to create deck */}
      <div 
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: '#007BFF',
          color: 'white',
          textAlign: 'center',
          padding: '15px',
          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
          cursor: 'pointer',
          fontSize: '18px',
          fontWeight: 'bold'
        }}
        onClick={handleCreateDeck}
      >
        Create Study Topic
      </div>
    </>
  );
};

export default BottomFunctionBar;
