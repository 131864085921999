// Flashcard.js
import React, { useState } from 'react';
import FlipFlashcard from './FlashcardTypes/FlipFlashcard';
import TypeAnswerFlashcard from './FlashcardTypes/TypeAnswerFlashcard';
// Import other flashcard type components as needed

const Flashcard = ({ flashcards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentFlashcard = flashcards[currentIndex];

  const renderFlashcardContent = () => {
    switch (currentFlashcard.type) {
      case 'flip':
        return <FlipFlashcard flashcard={currentFlashcard} />;
      case 'type_to_answer':
        return <TypeAnswerFlashcard flashcard={currentFlashcard} />;
      // Add other cases for different types
      default:
        return (
          <div>
            <p>Unknown flashcard type: {currentFlashcard.type}</p>
          </div>
        );    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < flashcards.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : flashcards.length - 1
    );
  };

  return (
    <div>
      {renderFlashcardContent()}
      <div className="navigation-buttons">
        <button onClick={handlePrev}>Previous</button>
        <span>
          {currentIndex + 1}/{flashcards.length}
        </span>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default Flashcard;
