import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import './UserInfoFormPage.css';

const UserInfoFormPage = () => {
  const [formData, setFormData] = useState({
    faculty: '',
    gradeLevel: '',
    degree: '',
    degreeType: '',
    studyHours: '',
    emailFrequency: '',
    studentType: '',
  });
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    // Check if we are editing (user has data)
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const userInfoRef = doc(userRef, 'user-info', 'details');

          const userInfoDoc = await getDoc(userInfoRef);

          if (userInfoDoc.exists()) {
            const data = userInfoDoc.data();
            setFormData({
              faculty: data.faculty || '',
              gradeLevel: data.gradeLevel || '',
              degree: data.degree || '',
              degreeType: data.degreeType || '',
              studyHours: data.studyHours || '',
              emailFrequency: data.emailFrequency || '',
              studentType: data.studentType || '',
            });
            setIsEditing(true);
          }
        } else {
          // No user signed in, redirect to sign-in page
          navigate('/sign-in');
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    fetchUserData();
  }, [auth, db, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear errors when the input changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (parseInt(formData.gradeLevel, 10) > 8) {
      newErrors.gradeLevel = 'Grade level must be 8 or lower';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userInfoRef = doc(userRef, 'user-info', 'details');

      // Save email frequency in the user document
      await setDoc(userRef, { emailFrequency: formData.emailFrequency }, { merge: true });

      // Save other information in the user-info subcollection
      await setDoc(userInfoRef, {
        faculty: formData.faculty,
        gradeLevel: formData.gradeLevel,
        degree: formData.degree,
        degreeType: formData.degreeType,
        studyHours: formData.studyHours,
        studentType: formData.studentType,
      });

      // Navigate to profile page
      navigate('/profile');
    } else {
      console.error('No user is signed in');
    }
  };

  const facultyOptions = [
    "Art, Design and Architecture",
    "Arts",
    "Business and Economics",
    "Education",
    "Engineering",
    "Information Technology",
    "Law",
    "Medicine, Nursing and Health Sciences",
    "Pharmacy and Pharmaceutical Sciences",
    "Science",
  ];

  const degreeTypeOptions = [
    "Bachelor",
    "Masters",
    "PhD",
    "College",
    "Other",
  ];

  const studentTypeOptions = [
    "Domestic",
    "International",
  ];

  return (
    <div className="user-info-container">
      <h2>{isEditing ? 'Edit Your Information' : 'Tell Us About Your Studies'}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Faculty:
          <select name="faculty" value={formData.faculty} onChange={handleChange} required>
            <option value="">Select Faculty</option>
            {facultyOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </label>
        <label>
          Grade Level:
          <input type="number" name="gradeLevel" value={formData.gradeLevel} onChange={handleChange} required />
          {errors.gradeLevel && <p className="error">{errors.gradeLevel}</p>}
        </label>
        <label>
          Degree:
          <input type="text" name="degree" value={formData.degree} onChange={handleChange} required />
        </label>
        <label>
          Degree Type:
          <select name="degreeType" value={formData.degreeType} onChange={handleChange} required>
            <option value="">Select Degree Type</option>
            {degreeTypeOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </label>
        <label>
          Study Hours per Week:
          <input type="number" name="studyHours" value={formData.studyHours} onChange={handleChange} required />
        </label>
        <label>
          Email Reminder Frequency:
          <select name="emailFrequency" value={formData.emailFrequency} onChange={handleChange} required>
            <option value="">Select Frequency</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </label>
        <label>
          Student Type:
          <select name="studentType" value={formData.studentType} onChange={handleChange} required>
            <option value="">Select Student Type</option>
            {studentTypeOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </label>
        <button type="submit">{isEditing ? 'Update' : 'Submit'}</button>
      </form>
    </div>
  );
};

export default UserInfoFormPage;
