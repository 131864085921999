// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getAnalytics, isSupported } from 'firebase/analytics';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBEE367kCBT44xCyCZ5SjkpbjfODL2FN70",
  authDomain: "bonsai-bdd50.firebaseapp.com",
  projectId: "bonsai-bdd50",
  storageBucket: "bonsai-bdd50.appspot.com",
  messagingSenderId: "462573559232",
  appId: "1:462573559232:web:594214483585a411ad7c25",
  measurementId: "G-XZD7YV2PE7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth(app);
// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Analytics
async function initAnalytics() {
  if (await isSupported()) {
    const analytics = getAnalytics(app);
    // You can proceed to use analytics here
  } else {
    console.warn('Analytics is not supported in this environment.');
  }
}

// Call the initialization function
initAnalytics();

export { db, auth };
