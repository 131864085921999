// FlipFlashcard.js
import React, { useState } from 'react';

const FlipFlashcard = ({ flashcard }) => {
  const [flipped, setFlipped] = useState(false);

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  return (
    <div className="flashcard-container" onClick={handleCardClick}>
      <div className={`flashcard ${flipped ? 'flipped' : ''}`}>
        {!flipped ? (
          <div className="front">{flashcard.front}</div>
        ) : (
          <div className="back">{flashcard.back}</div>
        )}
      </div>
    </div>
  );
};

export default FlipFlashcard;
