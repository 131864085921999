// src/components/StudyCreateContentComponents/FlashcardEntryComponent.js
import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase'; // Adjust the path as needed
import { collection, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import FlashcardEntryEditableComponent from './FlashcardEntryEditableComponent'

const FlashcardEntryComponent = ({ flashcards, setFlashcards, unitId, deckId }) => {
  // State to track flashcards (editable)
  const [editableFlashcards, setEditableFlashcards] = useState([]);

  useEffect(() => {
    // Initialize editable flashcards with the flashcards passed as props
    setEditableFlashcards(
      flashcards.map((card) => ({
        ...card,
        front: card.front || card.flashcard_front || '',
        back: card.back || card.flashcard_back || '',
        type: card.type || 'flip', // Default to 'flip' if type is undefined
        id: card.id || null,
      }))
    );
  }, [flashcards]);

  // Handler for updating a flashcard
  const handleFlashcardChange = (index, updatedFlashcard) => {
    const updatedFlashcards = [...editableFlashcards];
    updatedFlashcards[index] = updatedFlashcard;
    setEditableFlashcards(updatedFlashcards);
  };

  // Handler for deleting a flashcard
  const handleDeleteFlashcard = (index) => {
    const updatedFlashcards = [...editableFlashcards];
    const flashcardToDelete = updatedFlashcards[index];

    // If the flashcard has an ID, mark it for deletion from Firestore
    if (flashcardToDelete.id) {
      updatedFlashcards[index].toBeDeleted = true;
    } else {
      // Remove from the list if it's a new flashcard
      updatedFlashcards.splice(index, 1);
    }

    setEditableFlashcards(updatedFlashcards);
  };

  // Handler for adding a new flashcard
  const handleAddFlashcard = () => {
    setEditableFlashcards([
      ...editableFlashcards,
      { front: '', back: '', type: 'flip', id: null },
    ]);
  };

  const uploadToFirestore = async () => {
    try {
      // Reference to the flashcards collection in Firestore
      const flashcardsCollectionRef = collection(
        db,
        `units/${unitId}/decks/${deckId}/flashcards`
      );

      // Save flashcards to Firestore
      for (const flashcard of editableFlashcards) {
        if (flashcard.toBeDeleted) {
          // Delete flashcard from Firestore
          await deleteDoc(
            doc(db, `units/${unitId}/decks/${deckId}/flashcards`, flashcard.id)
          );
          continue;
        }

        if (!flashcard.front || !flashcard.back) {
          // Skip flashcards with empty front or back
          alert('Please fill in both the front and back of all flashcards.');
          return;
        }

        if (flashcard.id) {
          // Update existing flashcard
          const flashcardDocRef = doc(
            db,
            `units/${unitId}/decks/${deckId}/flashcards`,
            flashcard.id
          );
          await updateDoc(flashcardDocRef, {
            flashcard_front: flashcard.front,
            flashcard_back: flashcard.back,
            type: flashcard.type || 'flip', // Ensure type is saved
          });
        } else {
          // Add new flashcard
          await addDoc(flashcardsCollectionRef, {
            flashcard_front: flashcard.front,
            flashcard_back: flashcard.back,
            type: flashcard.type || 'flip', // Ensure type is saved
          });
        }
      }

      alert('Flashcards uploaded successfully!');
    } catch (error) {
      console.error('Error uploading flashcards:', error);
      alert('An error occurred while uploading flashcards.');
    }
  };

  return (
    <div>
      <h3>Generated Flashcards</h3>
      {editableFlashcards.length === 0 ? (
        <p>No flashcards available.</p>
      ) : (
        <div>
          {editableFlashcards.map(
            (card, index) =>
              !card.toBeDeleted && (
                <FlashcardEntryEditableComponent
                  key={index}
                  flashcard={card}
                  onChange={(updatedCard) => handleFlashcardChange(index, updatedCard)}
                  onDelete={() => handleDeleteFlashcard(index)}
                />
              )
          )}
        </div>
      )}
      <button onClick={handleAddFlashcard} style={{ marginTop: '1rem' }}>
        Add Flashcard
      </button>
      {/* Add a button to finalize and upload to Firestore */}
      <button onClick={uploadToFirestore} style={{ marginTop: '1rem' }}>
        Finalize and Upload
      </button>
    </div>
  );
};

export default FlashcardEntryComponent;