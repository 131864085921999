import React, { useState } from 'react';

const TextAreaWithShortcuts = ({ initialContent, onContentChange }) => {
  const [content, setContent] = useState(initialContent || '');
  const [lastDividerType, setLastDividerType] = useState('end'); // Track the last divider type

  const handleChange = (e) => {
    setContent(e.target.value);
    onContentChange(e.target.value); // Pass content to parent component if needed
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
        const cursorPosition = e.target.selectionStart;
        const textBeforeCursor = content.slice(0, cursorPosition);
        const textAfterCursor = content.slice(cursorPosition);

        if (textBeforeCursor.endsWith('---')) {
            e.preventDefault();

        // Toggle between start and end card markers based on the last divider
        const replacementText =
          lastDividerType === 'end'
            ? '--- Start of Card ---'
            : '--- End of Card ---';

        const newContent = textBeforeCursor.slice(0, -4) + `\n${replacementText}\n` + textAfterCursor;
        
        setContent(newContent);
        onContentChange(newContent);

        // Update the last divider type to the opposite
        setLastDividerType(lastDividerType === 'end' ? 'start' : 'end');

        } else if (textBeforeCursor.endsWith('###')) {
            e.preventDefault();
            const newContent = textBeforeCursor.slice(0, -4) +
            `\n#############################################\n` +
            textAfterCursor;
            setContent(newContent);
            onContentChange(newContent);
        }
    }
};


  return (
    <textarea
      value={content}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      rows={10}
      style={{
        flex: 1, // Make the textarea fill the available space
        width: '100%',
        padding: '1rem',
        marginBottom: '1rem',
        boxSizing: 'border-box',
        color: 'black', // Set text color to black
        backgroundColor: 'white', // Set background color
      }}
      placeholder="Type '---' for Start of Card, '###' for Front-Back Divider"
    />
  );
};

export default TextAreaWithShortcuts;
