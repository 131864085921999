import PDFViewerPage from './PDFViewerPage';
import { useParams } from 'react-router-dom';


function FlashPDFPage() {
  const { unitId, deckId } = useParams();

    return (
      <div className="FlashPDFPage">
      <PDFViewerPage unitId={unitId} deckId={deckId} />
      </div>
    );
  }
  
  export default FlashPDFPage;
  